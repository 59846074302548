export const maxBy = <T>(elements: T[], comparisonTransform: (t: T) => number): T | undefined => {
  if (elements.length === 0) {
    return undefined;
  }

  let maxValue = Number.MIN_VALUE;
  let maxElement = elements[0];

  for (const element of elements) {
    const value = comparisonTransform(element);
    if (value > maxValue) {
      maxValue = value;
      maxElement = element;
    }
  }

  return maxElement;
};

export const minBy = <T>(elements: T[], comparisonTransform: (t: T) => number): T | undefined =>
  maxBy(elements, (element) => -comparisonTransform(element));

export const groupBy = <T>(elements: T[], propertyKey: keyof T): Record<string, T[]> => {
  return elements.reduce((result: Record<string, T[]>, currentValue: T) => {
    const groupKey = String(currentValue[propertyKey]);
    if (!groupKey) return result;

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(currentValue);
    return result;
  }, {}) as Record<string, T[]>;
};
